import { useLinks } from '@bluheadless/ui-logic/src/hooks/useLinks'
import { cx } from '@emotion/css'
import { useTrackingsActions } from '@bluheadless/ui-logic/src/hooks/trackings'
import { Fragment } from 'react'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { ImageBackgroundArtDirection, VideoBackgroundArtDirection } from '@bluheadless/ui/src/particles/art-direction'
import HeroBanner from '@bluheadless/ui/src/organisms/hero/hero-banner'

const BannerCover = ({
	aboveTheFold,
	contentType,
	minHeight,
	title,
	titleTag,
	titleColor,
	titleColorMobile,
	subtitle,
	subtitleTag,
	subtitleColor,
	subtitleColorMobile,
	ctas,
	content,
	horizontalAlign,
	image,
	imageConfig,
	video,
	videoConfig,
	seoBannerId,
	seoBannerName,
	seoBannerCreative,
	seoBannerPosition,
	sx,
	cssProp,
	className,
	RichContentComponent,
}) => {
	const links = useLinks([
		{ type: ctas?.[0]?.linkType, href: ctas?.[0]?.link },
		{ type: ctas?.[1]?.linkType, href: ctas?.[1]?.link },
		{ type: ctas?.[2]?.linkType, href: ctas?.[2]?.link },
	])

	const { promotionClick } = useTrackingsActions()

	const trackClickEvent = (args) => {
		promotionClick({ ...args })
	}

	const hasImage = !!image?.mobile?.src
	const hasVideo = !!video?.mobile?.url

	if (!hasImage && !hasVideo) {
		console.warn(`[RichContent] PageBuilder "${contentType}" has no image or video`)
	}

	const BackgroundComponent = hasVideo ? VideoBackgroundArtDirection : ImageBackgroundArtDirection

	const { siteName } = useConfig()

	return (
		<HeroBanner
			aboveTheFold={true}
			sx={{ ...sx, minHeight, height: '100%' }}
			css={cssProp}
			className={cx('Magento-PageBuilder-Banner-Cover', className)}
			backgroundComponent={
				<>
					{(hasImage || hasVideo) && (
						<BackgroundComponent
							aboveTheFold={aboveTheFold}
							alt={title ? title + ' | ' + siteName : siteName}
							{...(hasVideo ? videoConfig : {})}
							sources={{
								xs: hasVideo ? { ...video.mobile, light: false } : image.mobile,
								md: hasVideo ? { ...video.desktop, light: false } : image.desktop,
							}}
							style={{
								height: '100%',
							}}
							{...(hasImage ? imageConfig : {})}
						/>
					)}
				</>
			}
			titleProps={{ component: titleTag, sx: { color: { xs: titleColorMobile, md: titleColor } } }}
			title={title && RichContentComponent ? <RichContentComponent content={title} component={Fragment} /> : null}
			subtitleProps={
				subtitle ? { component: subtitleTag, sx: { color: { xs: subtitleColorMobile, md: subtitleColor } } } : undefined
			}
			subtitle={subtitle}
			content={content && RichContentComponent ? <RichContentComponent content={content} component={Fragment} /> : null}
			descriptionProps={{ sx: { color: { md: titleColor, xs: titleColorMobile } } }}
			cta={ctas?.map(({ variant, text, color, openInNewTab }, i) => ({
				variant,
				label: text,
				color,
				colorHex: titleColor,
				url: links[i],
				target: openInNewTab ? '_blank' : '_self',
				onClick: (e) => {
					if (seoBannerId && seoBannerName && seoBannerCreative && seoBannerPosition) {
						trackClickEvent({
							event: e,
							id: seoBannerId,
							name: seoBannerName,
							creative: seoBannerCreative,
							position: seoBannerPosition,
						})
					}
				},
			}))}
			horizontalAlign={horizontalAlign}
		/>
	)
}

export default BannerCover
